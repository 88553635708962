import { render, staticRenderFns } from "./Mato-Grosso-do-Sul.vue?vue&type=template&id=c75041ba&scoped=true&"
import script from "./Mato-Grosso-do-Sul.vue?vue&type=script&lang=js&"
export * from "./Mato-Grosso-do-Sul.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c75041ba",
  null
  
)

export default component.exports